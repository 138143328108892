<template>
    <section class="section-hero">
        <div class="hero-content">
            <h1 class="heading-primary">{{$t('about.title')}}</h1>
            <div class="hidden-founder hero-founder ceo-founder">
                <div class="founder-avatar">
                    <Avatar image="/assets/GEC_9222 final3.jpg" shape="circle" />
                    <h2 class="founder-heading">
                        Jennifer Cameron CPA CFA
                        <br>
                        CEO & Co-founder
                    </h2>
                </div>

                <div class="founder-description">
                    <p>
                        {{$t('about.jenLine1')}}
                        <br>
                         {{$t('about.jenLine2')}}
                        <br>
                         {{$t('about.jenLine3')}}
                    </p>
                </div>
            </div>
            <div class="hidden-founder hero-founder cto-founder">
                <div class="founder-avatar">
                    <Avatar image="/assets/cto-founder.jpg" shape="circle" />
                    <h2 class="founder-heading">
                        Dege Ratak
                        <br>
                        CTO & Co-founder
                    </h2>
                </div>

                <div class="founder-description">
                    <p>
                         {{$t('about.degeLine1')}}
                        <br>
                         {{$t('about.degeLine2')}}
                        <br>
                         {{$t('about.degeLine3')}}
                        <br>
                         {{$t('about.degeLine4')}}
                    </p>
                </div>
            </div>
        </div>
    </section>
  </template>
  
  <script>
  import Avatar from 'primevue/avatar';
  

  export default {
    components: {
        Avatar
    },

    head() {
      return {
        title: "INVRS - Our Team.",
        meta:[
          { 
            name: "description",
            content: 'The founders of INVRS combine financial and technical know-how with a deep desire to help people become more prosperous.'
          },
          { 
            name: "twitter:title",
            content: 'INVRS - Our Team.'
          },
          { 
            name: "twitter:description",
            content: 'The founders of INVRS combine financial and technical know-how with a deep desire to help people become more prosperous.'
          },
          { 
            name: "og:title",
            content: 'INVRS - Our Team.'
          },
          { 
            name: "og:description",
            content: 'The founders of INVRS combine financial and technical know-how with a deep desire to help people become more prosperous.'
          },
        ]
      }
    },

    data() {
      return {
        heroSection: "",
        heroContent: "",
        ceoFounder: "",
        ctoFounder: "",
        ceoFounderImagePath: "../../assets/ceo-founder.jpg",
        obsOptionsHero: {
          root: null,
          threshold: 0.1,
        },
      };
    },
    mounted() {
      this.heroSection = document.querySelector(".section-hero");
      this.ceoFounder = document.querySelector(".ceo-founder");
      this.ctoFounder = document.querySelector(".cto-founder");
      const heroObserver = new IntersectionObserver(
        this.revealHero,
        this.obsOptionsHero
      );
      heroObserver.observe(this.heroSection);
    },
    methods: {
      revealFounders() {
        this.ceoFounder.classList.remove("hidden-founder");
        this.ctoFounder.classList.remove("hidden-founder");
      },
      revealHero() {
        this.revealFounders();
      },
    },
  };
  </script>
  
  <style scoped>
  .section-hero {
    position: relative;
    padding: 12.8rem 0 9.6rem 0;
    background:  #e0f6ed;
  }
  
  .hero-content {
    max-width: 160rem;
    margin: 0 auto;
    padding: 0 3.6rem;
  }

  .heading-primary {
    margin-bottom: 4.8rem;
  }

  .hidden-founder {
    opacity: 0;
  }
  .hidden-content {
    transform: translateX(-9.6rem);
    opacity: 0;
  }
  .hidden-founder.ceo-founder {
    transform: translateX(-9.6rem);
  }

  .hero-founder {
    display: flex;
    align-items: center;
    margin-bottom: 5rem;
    transition: all 0.6s ease-in;
  }
  .hero-founder:last-of-type() {
    margin-bottom: 0;
  }

  .founder-heading {
    color: #32364e;
    font-size: 2.5rem;
  }

  .founder-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .founder-avatar .p-avatar {
    width: 18rem;
    height: 18rem;
    border: 3px solid #32364e;
  }

  .founder-description {
    padding: 2rem;
    border-radius: 25px;
    line-height: 1.6;
    color: #333;
    max-width: 100rem;
    background: #fff;
    box-shadow: 0 0 2.4rem hsl(231deg 22% 25% / 20%);
  }

  .ceo-founder .founder-avatar {
    margin-right: 6rem;
  }
  
  .hidden-founder.cto-founder {
    transform: translateX(9.6rem);
  }

  .cto-founder {
    flex-direction: row-reverse;
  }
  
  .cto-founder .founder-avatar {
    margin-left: 6rem;
  }
  /* **************************** */
  /* BELOW 1360px (Small desktop/laptop) */
  /* **************************** */
  @media (max-width: 85em) {
    .hero-content {
        max-width: 150rem;
    }
  
    .heading-primary {
      font-size: 6rem;
    }

    .founder-description {
        max-width: 75rem;
    }
  }
  
  /* **************************** */
  /* BELOW 976px (landscape tablets) */
  /* **************************** */
  @media (max-width: 61em) {
    .hero-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .hero-founder {
        flex-direction: column;
        justify-content: center;
    }

    .founder-avatar {
        margin: 0 6rem 0;
    }

    .founder-description,
    .cto-founder .founder-description {
        text-align: center;
        max-width: 90rem;
    }

  
    .heading-primary {
      font-size: 5.6rem;
    }
  }
  
  /* **************************** */
  /* BELOW 800px (tablet) */
  /* **************************** */
  @media (max-width: 50em) {
  }
  
  /* **************************** */
  /* BELOW 592px (mobile) */
  /* **************************** */
  @media (max-width: 37em) {
  
    .heading-primary {
      font-size: 5rem;
    }

    .founder-heading {
        font-size: 2rem;
    }

    .founder-avatar .p-avatar {
        width: 15rem;
        height: 15rem;
    }

    .founder-description {
        padding: 1.2rem;
        font-size: 1.8rem;
        border-radius: 10px;
    }
  }
  
  /* **************************** */
  /* BELOW 496px (mobile) */
  /* **************************** */
  @media (max-width: 31em) {
    .heading-primary {
      font-size: 4.4rem;
    }
  }
  </style>
  