<template>
  <section class="section-hero">
    <div class="hero container">
      
      <div class="hero-content hidden-content">
        <h1 class="heading-primary">FinTrivia Investment Knowledge Competition.</h1>
        <p class="hero-description">
          FinTrivia is a thrice daily financially themed multiple-choice trivia contest that awards correct answers with Playdough.  Every month we award either the highest score or the longest streak with a badge and a cash prize. <br>Note: subscribers to our investment research tools earn double!
        </p>
        <button @click="goToContestDetails">
          <span>See contest details</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 74 74"
          >
            <path
              fill="white"
              d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z"
            ></path>
          </svg>
        </button>
      </div>
      <div class="hero-img-box hidden-img">
        <img class="hero-img" alt="Box of cash" src="../../assets/fintrivia.png" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      heroSection: "",
      heroContent: "",
      heroImage: "",
      obsOptionsHero: {
        root: null,
        threshold: 0.1,
      },
    };
  },
  mounted() {
    this.heroSection = document.querySelector(".section-hero");
    this.heroContent = document.querySelector(".hero-content");
    this.heroImage = document.querySelector(".hero-img-box");
    const heroObserver = new IntersectionObserver(
      this.revealHero,
      this.obsOptionsHero
    );
    heroObserver.observe(this.heroSection);
  },
  methods: {
    revealHeroContent() {
      this.heroContent.classList.remove("hidden-content");
    },
    revealHeroImage() {
      this.heroImage.classList.remove("hidden-img");
    },
    revealHero() {
      this.revealHeroContent();
      this.revealHeroImage();
    },
    goToContestDetails() {
      
      this.$router.push({ name: "fintrivia-rules" });
    },
  },
};
</script>

<style scoped>
.heading-primary {
  font-size:4.6rem;
}
.section-hero {
  position: relative;
  padding: 12.8rem 0 9.6rem 0;
  background: linear-gradient(130deg, #fff 0%, #edfff8 70%, #e0f6ed 80%);
  transform: skewY(3deg);
}

.hero {
  max-width: 130rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  transform: skewY(-3deg);
}

.hero-img-box {
  display: flex;
  justify-content: right;
  align-items: center;
  transition: all 0.3s ease-in;
}

.hero-img {
  margin-left: 4.8rem;
  width: 45rem;
  display: inline-block;
  filter: drop-shadow(0px 24px 38px rgba(0, 0, 0, 0.14))
    drop-shadow(0px 9px 46px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.2));
}

.hidden-img {
  transform: translateX(9.6rem);
  opacity: 0;
}

.hidden-content {
  transform: translateX(-9.6rem);
  opacity: 0;
}

.hero-content {
  transition: all 0.3s ease-in;
}

.hero-description {
  line-height: 1.6;
  margin-bottom: 4.8rem;
  max-width: 100rem;
  color: #333;
}

.join-flex {
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
}

.join-users {
  display: flex;
  margin-right: 1.6rem;
}

.join-users img {
  width: 3.5rem;
  border-radius: 50%;
  border: 3px solid #edfff8;
  margin-right: -0.8rem;
}

.join-text {
  font-size: 1.8rem;
  font-weight: 600;
}

.join-text > span {
  color: rgb(105, 59, 245, 1);
  font-weight: 700;
}

button {
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  color: #fff;
  transition: all 0.2s;
  padding: 10px 20px;
  border-radius: 0.8rem;
  background-image: linear-gradient(
    to right,
    rgb(105, 59, 245, 1),
    rgb(105, 59, 245, 0.85),
    rgb(105, 59, 245, 0.7)
  );
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 2rem;
}

button:hover {
  box-shadow: 0 0.4rem 3.2rem rgb(105, 59, 245, 0.6);
  transform: scale(1.05);
}

button > svg {
  width: 3.6rem;
  margin-left: 1rem;
  transition: transform 0.3s ease-in-out;
  font-weight: 700;
}

button:hover svg {
  transform: translateX(5px);
}

button:active {
  transform: scale(0.95);
}

/* **************************** */
/* BELOW 1360px (Small desktop/laptop) */
/* **************************** */
@media (max-width: 85em) {
  .hero {
    max-width: 120rem;
  }

  .heading-primary {
    font-size: 6rem;
  }

  .hero-img {
    width: 40rem;
  }
}

/* **************************** */
/* BELOW 976px (landscape tablets) */
/* **************************** */
@media (max-width: 61em) {
  .heading-primary {
    font-size: 5.6rem;
  }

  .hero-img {
    width: 36rem;
  }
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .hero-content {
    width: 80%;
  }

  .hero-img {
    width: 30rem;
    position: absolute;
    right: 0%;
    transform: translateX(35%);
  }

  .hero-img-box {
    transition: all 0s ease-in;
  }

  .hidden-img {
    position: absolute;
    right: 0%;
    transform: translateX(45%);
    opacity: 0;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .heading-primary {
    font-size: 5rem;
  }

  .hero-description {
    width: 90%;
  }
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
  .heading-primary {
    font-size: 4.4rem;
  }

  .hero-content {
    width: 100%;
  }

  .hero-img {
    width: 26.4rem;
    right: 0%;
    bottom: 0%;
    transform: translate(30%, 80%);
  }

  .join-flex {
    flex-direction: column;
    align-items: flex-start;
  }

  .join-users {
    margin-bottom: 1.2rem;
  }

  button {
    padding: 8px 16px;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .hero-img {
    width: 24rem;
    transform: translate(20%, 70%);
  }

  .heading-primary {
    font-size: 4rem;
  }
}
</style>
