<template>
  <section class="section-mobile-menu">
    <nav>
      <ul class="nav-container">
        <li class="nav-link-container" @click="toggleYourJourneyDrop">
          <router-link
            class="your-journey"
            :to="{ name: 'home' }"
            @click="menuItemClick"
            >{{$t('Your Journey')}}</router-link
          >
          <img
            src="../../assets/chevron-down-outline.svg"
            :class="{
              'nav-arrow-flipped': yourJourneyDropOpen,
            }"
          />
        </li>
        <transition>
          <mobile-your-journey-dropdown
            v-show="yourJourneyDropOpen"
            @your-journey-option-click="yourJourneyOptionClick"
          ></mobile-your-journey-dropdown
        ></transition>
        <li class="nav-link-container">
          <router-link :to="{ name: 'courses' }" @click="menuItemClick"
            >{{$t('Courses')}}</router-link
          >
        </li>
        <li class="nav-link-container">
          <router-link :to="{ name: 'about-us' }" @click="menuItemClick"
            >{{$t('About Us')}}</router-link
          >
        </li>
        
        <li class="nav-link-container">
          <router-link :to="{ name: 'learn2earnContest' }" @click="menuItemClick"
            >{{$t('Learn2Earn')}}</router-link
          >
        </li>
         <li class="nav-link-container">
          <router-link :to="{ name: 'fintrivia' }" @click="menuItemClick"
            >FinTrivia</router-link
          >
        </li>
        <li class="nav-link-container">
          <router-link :to="{ name: 'feed' }" @click="menuItemClick"
            >{{$t('Latest Posts')}}</router-link
          >
        </li>
       
      </ul>
    </nav>
    <nav>
      <ul>
        <li class="cta-container">
          <a class="cta btn" href="#" @click="goToSignup">{{$t('Sign Up')}}</a>
        </li>
        <li class="cta-container">
          <a href="#" @click="goToLogin">{{$t('Login')}}</a>
        </li>
        <li class="cta-container">
          <SelectButton v-model="lang" :options="langOptions" />
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
import MobileYourJourneyDropdown from "./MobileYourJourneyDropdown.vue";
import { SIGNUP_URL, LOGIN_URL } from "../../config.js";

import SelectButton from 'primevue/selectbutton';

import i18nService from '../../i18n';

export default {
  components: {
    "mobile-your-journey-dropdown": MobileYourJourneyDropdown,
    SelectButton
  },
  data() {
    return {
      yourJourneyDropOpen: false,
      menuItemClicked: false,
      lang: (i18nService.currentLocale == 'en'?'English': 'Français'),
      langOptions: ['English', 'Français'],
    };
  },

  watch: {
    lang(newVal, oldVal){
      console.log("old val "+oldVal+"  new val "+newVal);
      if( newVal == 'English' ){
        i18nService.changeLocale('en');
      }
      else if( newVal == 'Français' ){
        i18nService.changeLocale('fr');
      }
    }
  },
  methods: {
    toggleYourJourneyDrop() {
      if (this.menuItemClicked) {
        this.menuItemClicked = false;
        return;
      }
      this.yourJourneyDropOpen = !this.yourJourneyDropOpen;
    },
    menuItemClick() {
      this.menuItemClicked = true;
      this.yourJourneyDropOpen = false;
      this.$emit("menuItemClick");
    },
    yourJourneyOptionClick(id) {
      this.yourJourneyDropOpen = false;
      this.$emit("your-journey-option-click", id);
    },
    goToSignup() {
      window.location.href = SIGNUP_URL;
      this.menuItemClicked = true;
      this.yourJourneyDropOpen = false;
      this.$emit("menuItemClick");
    },
    goToLogin() {
      window.location.href = LOGIN_URL;
      this.menuItemClicked = true;
      this.yourJourneyDropOpen = false;
      this.$emit("menuItemClick");
    },
  },
};
</script>

<style scoped>
.section-mobile-menu {
  width: 100%;
  background-color: #fff;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 12rem;
}

.nav-container {
  margin-bottom: 2.4rem;
}

.nav-container:last-child {
  border-bottom: 2px solid #f2f4fa;
}

.nav-link-container {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #f2f4fa;
  padding: 1.6rem 3.2rem;
}

.cta-container {
  display: flex;
  justify-content: center;
  padding: 1.2rem 3.2rem;
}

.cta {
  padding: 1.2rem 2.4rem;
  border-radius: 8px;
  font-weight: 500;
  color: #fff;
  background-image: linear-gradient(
    to right,
    #693bf5,
    rgb(105, 59, 245, 0.85),
    rgb(105, 59, 245, 0.7)
  );
}

.cta:hover {
  transform: scale(1.05);
  color: #fff;
}

.cta:active {
  transform: scale(0.95);
  color: #fff;
}

li img {
  height: 2rem;
  color: #333;
  transition: all 0.2s ease-in;
}

li {
  list-style: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 500;
  color: inherit;
}

a:hover,
a:active,
a.active {
  color: #333;
}

.nav-arrow-flipped {
  transform: rotate(-180deg);
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateY(1.2rem);
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease-in;
}

.v-move {
  transition: all 0.3s ease-in;
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .nav-link-container {
    padding: 1.6rem 2.4rem;
  }
}
</style>
