<template>
  <section class="section-hero">
    <div class="hero container">
      <div class="hero-content hidden-content">
        <h1 class="heading-primary">{{$t('contact.title')}}</h1>
        <p class="hero-description">
         {{$t('contact.desc')}}
        </p>
      </div>
      <div class="hero-contact hidden-contact">
        <p class="submit-text" v-if="emailSubmitted">{{$t('contact.thanks')}}</p>
        <form @submit.prevent="submitContact" v-else>
          <input
            type="email"
            :placeholder="$t('footer.placeholder')+'@email.com'"
            v-model="userEmail"
          />
          <input type="text" :placeholder="$t('contact.Your first name')" v-model="userName" />
          <select name="topic" id="topic" v-model="userTopic">
            <option value="">{{$t('contact.topic')}}</option>
            <option value="Feedback">{{$t('contact.Feedback')}}</option>
            <option value="Support">{{$t('contact.Support')}}</option>
            <option value="Fundraising">{{$t('contact.Fundraising')}}</option>
          </select>
          <textarea :placeholder="$t('contact.Your message')" v-model="userMessage" />
          <button>{{$t('contact.send')}}</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactPage",

  head() {
      return {
        title: "INVRS - Contact Us.",
        meta:[
          { 
            name: "description",
            content: 'If you have any questions or are interested in how INVRS can help your organization deliver powerful custom financial education, contact us.'
          },
          { 
            name: "twitter:title",
            content: 'INVRS - Contact Us.'
          },
          { 
            name: "twitter:description",
            content: 'If you have any questions or are interested in how INVRS can help your organization deliver powerful custom financial education, contact us.'
          },
          { 
            name: "og:title",
            content: 'INVRS - Contact Us.'
          },
          { 
            name: "og:description",
            content: 'If you have any questions or are interested in how INVRS can help your organization deliver powerful custom financial education, contact us.'
          },
        ]
      }
    },

  data() {
    return {
      userEmail: "",
      userName: "",
      userTopic: "",
      userMessage: "",
      portalId: "20584317",
      formGuid: "5b48b42b-d2c3-4bcd-87f9-b82323b03704",
      heroSection: "",
      heroContent: "",
      heroContact: "",
      obsOptionsHero: {
        root: null,
        threshold: 0.1,
      },
      emailSubmitted: false,
    };
  },
  mounted() {
    this.heroSection = document.querySelector(".section-hero");
    this.heroContent = document.querySelector(".hero-content");
    this.heroContact = document.querySelector(".hero-contact");
    const heroObserver = new IntersectionObserver(
      this.revealHero,
      this.obsOptionsHero
    );
    heroObserver.observe(this.heroSection);
  },
  methods: {
    revealHeroContent() {
      this.heroContent.classList.remove("hidden-content");
    },
    revealHeroContact() {
      this.heroContact.classList.remove("hidden-contact");
    },
    revealHero() {
      this.revealHeroContent();
      this.revealHeroContact();
    },
    async submitContact() {
      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formGuid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: [
              {
                objectTypeId: "0-1",
                name: "email",
                value: this.userEmail,
              },
              {
                objectTypeId: "0-1",
                name: "firstname",
                value: this.userName,
              },
              {
                objectTypeId: "0-1",
                name: "topic",
                value: this.userTopic,
              },
              {
                objectTypeId: "0-1",
                name: "message",
                value: this.userMessage,
              },
            ],
          }),
        }
      );
      if (response.ok) {
        this.emailSubmitted = true;
      }
    },
  },
};
</script>

<style scoped>
.section-hero {
  position: relative;
  padding: 12.8rem 0 9.6rem 0;
}

.hero {
  max-width: 130rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.hidden-content {
  transform: translateX(-9.6rem);
  opacity: 0;
}

.hero-content {
  transition: all 0.3s ease-in;
  margin-right: 4.8rem;
}

.hero-description {
  line-height: 1.6;
  margin-bottom: 4.8rem;
  max-width: 100rem;
  color: #333;
}

.hero-contact {
  border-radius: 16px;
  transition: all 0.3s ease-in;
  width: 80%;
  padding: 4.8rem;
  background-image: linear-gradient(
    to right,
    rgb(105, 59, 245, 1),
    rgb(105, 59, 245, 0.9),
    rgb(105, 59, 245, 0.8)
  );
}

.hidden-contact {
  transform: translateX(9.6rem);
  opacity: 0;
}

.submit-text {
  color: #fff;
  text-align: center;
  line-height: 1.3;
}

form {
  display: flex;
  flex-direction: column;
}

form {
  width: 100%;
  display: flex;
  align-items: center;
}

input,
textarea,
select {
  transition: all 0.2s ease-in;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 2.4rem;
  padding: 1.2rem;
  font-size: 1.8rem;
  background: #f7f8fc;
  border: 2px solid transparent;
  color: #333;
  font-family: inherit;
}

input:hover,
input:focus,
textarea:hover,
textarea:focus,
select:hover,
select:focus {
  outline: none;
  border: 2px solid #33cc99;
}

textarea {
  height: 13rem;
}

::placeholder {
  color: #a5a5a5;
}

button {
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  color: #fff;
  transition: all 0.2s;
  padding: 10px 20px;
  border-radius: 0.8rem;
  background-image: linear-gradient(
    to right,
    rgb(51, 204, 153, 1),
    rgb(51, 204, 153, 0.9),
    rgb(51, 204, 153, 0.8)
  );
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 2rem;
}

button:hover {
  box-shadow: 0 0.4rem 2.4rem rgb(51, 204, 153, 0.6);
  transform: scale(1.05);
}

button:active {
  transform: scale(0.95);
}

/* **************************** */
/* BELOW 1360px (Small desktop/laptop) */
/* **************************** */
@media (max-width: 85em) {
  .hero-contact {
    padding: 3.6rem;
  }

  .heading-primary {
    font-size: 6rem;
  }
}

/* **************************** */
/* BELOW 976px (landscape tablets) */
/* **************************** */
@media (max-width: 61em) {
  .hero {
    flex-direction: column;
  }

  .hero-contact {
    padding: 4.8rem;
  }

  .hero-content {
    margin-right: 0;
  }

  .heading-primary {
    font-size: 5.6rem;
  }
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .hero-contact {
    width: 100%;
    padding: 3.6rem;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .hero-contact {
    padding: 2.4rem;
  }

  input,
  textarea,
  select {
    margin-bottom: 1.8rem;
  }

  .heading-primary {
    font-size: 5rem;
  }
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
  .heading-primary {
    font-size: 4.4rem;
  }
}
</style>
