<template>
  <section>
    <div class="container hidden section-steps">
      <div class="section-header">
        <span class="subheading">Steps</span>
        <h2 class="heading-secondary">Here's how to enter:</h2>
      </div>
      <div class="grid grid--12-cols">
        <div class="step-box step-1 hidden">
          <img src="../../assets/step1.png" class="step-icon" />
          <h3 class="heading-tertiary " >Sign up</h3>
          <p class="step-description">
            <span class="signup-step" @click="goToSignup">Create your free INVRS account</span> to get access to the contest.
          </p>
        </div>
       <div class="step-box step-2 hidden">
          <img src="../../assets/step2.png" class="step-icon" />
          <h3 class="heading-tertiary">Start Answering Questions</h3>
          <p class="step-description">
            By answering FinTrivia questions on the main feed you are automatically registered in the contest.
          </p>
        </div>
        <!-- <div class="step-box step-3 hidden">
          <img src="../../assets/step3.png" class="step-icon" />
          <h3 class="heading-tertiary">Start Investing</h3>
          <p class="step-description">
            You'll be issued an investment account pre-funded with $100,000 USD Playdough.  Now you can start placing trades.
          </p>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import { SIGNUP_URL } from "../../config.js";
export default {
  data() {
    return {
      steps: [],
      obsOptionsSteps: {
        root: null,
        threshold: 0.5,
      },
      obsOptionsSection: {
        root: null,
        threshold: 0.2,
      },
      section: "",
    };
  },
  mounted() {
    this.steps = document.querySelectorAll(".step-box");
    this.section = document.querySelector(".section-steps");
    const stepsObserver = new IntersectionObserver(
      this.revealSteps,
      this.obsOptionsSteps
    );
    const sectionObserver = new IntersectionObserver(
      this.revealSection,
      this.obsOptionsSection
    );
    stepsObserver.observe(this.section);
    sectionObserver.observe(this.section);
  },
  methods: {
    revealSteps(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.steps.forEach((v, i) => {
            setTimeout(() => v.classList.remove("hidden"), i * 100);
          });
        }
      });
    },
    revealSection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.section.classList.remove("hidden");
        }
      });
    },
     goToSignup() {
      window.location.href = SIGNUP_URL;
    },
  },
};
</script>

<style scoped>
.signup-step{
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #693bf5;
}
.section-steps {
  padding: 9.6rem 3.2rem;
  transition: all 0.3s ease-in;
}

.step-description {
  font-size: 1.8rem;
  line-height: 1.6;
}

.step-icon {
  margin-bottom: 1.6rem;
  width: 6.4rem;
}

.step-box {
  transition: all 0.3s ease-in;
  grid-column: 1, 12;
}

.step-1 {
  grid-column: 1 / 5;
}
.step-2 {
  grid-column: 5 / 9;
}
.step-3 {
  grid-column: 9 / 13;
}

.hidden {
  transform: translateY(4.8rem);
  opacity: 0;
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .step-box {
    grid-column: 1/-1;
  }
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
  .step-icon {
    width: 4.8rem;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .section-steps {
    padding: 9.6rem 2.4rem;
  }
}
</style>
