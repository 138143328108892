import { createRouter, createWebHistory } from "vue-router";
import i18nService from "./i18n"

import HomePage from "./pages/HomePage.vue";
import CoursePage from "./pages/CoursesPage.vue";
import AboutUsPage from "./pages/AboutUsPage.vue";
import ContactPage from "./pages/ContactPage.vue";
import Learn2EarnContestPage from "./pages/Learn2EarnContestPage.vue"
import FintriviaPage from "./pages/FintriviaPage.vue"

const router = createRouter({
  history: createWebHistory(),
//   scrollBehavior: function(to, from, savedPosition) {//eslint-disable-line
//     if (to.hash) {
//        return {el: to.hash}
//     } else {
//         return { x: 0, y: 0 }
//     }
// },

  routes: [
    { path: "/", alias: "/home",
      component: HomePage ,
      name: "home",
    },
    {
      path: "/courses",
      component: CoursePage,
      name: "courses",
    },
    {
      path: "/about-us",
      component: AboutUsPage,
      name: "about-us",
    },
    // {
    //   path: "/creators",
    //   component: () => import("./pages/CreatorPage.vue"),
    //   name: "creators",
    // },
    // {
    //   path: "/plans",
    //   component: () => import("./pages/PricingPage.vue"),
    //   name: "plans",
    // },
    // {
    //   path: "/contest",
    //   component: () => import("./pages/ContestPage.vue"),
    //   name: "contest",
    //   children: [
    //     {
    //       path: "growth2022",
    //       component: Growth2022,
    //       name: "growth2022",
    //     },
    //   ],
    // },
     {
      path: "/learn2earn",
      component: Learn2EarnContestPage,
      name: "learn2earnContest",
    },
    {
      path: "/well-vrsd",
      component: () => import("./pages/NewsletterPage.vue"),
      name: "newsletter",
    },
    {
      path: "/terms",
      component: () => import("./pages/TermsPage.vue"),
      name: "terms",
    },
    {
      path: "/privacy",
      component: () => import("./pages/PrivacyPage.vue"),
      name: "privacy",
    },
    {
      path: "/contact",
      component: ContactPage,
      name: "contact",
    },
    {
      path: "/feed",
      component: () => import("./pages/FeedPage.vue"),
      name: "feed",
    },
    { path: "/fintrivia",
      component: FintriviaPage,
      name: "fintrivia",
    },
    // {
    //   path: "/showdown-rules",
    //   component: () => import("./pages/ShowdownRulesPage.vue"),
    //   name: "showdown-rules",
    // },
    {
      path: "/fintrivia-rules",
      component: () => import("./pages/FinTriviaRulesPage.vue"),
      name: "fintrivia-rules",
    },
    {
      path: "/learn2earn-rules",
      component: () => import("./pages/Learn2EarnRulesPage.vue"),
      name: "learn2earn-rules",
    },
    //{ path: "/:notFound(.*)", redirect: "/home" },
  ],
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {//eslint-disable-line
  i18nService.initLocale();
  
  return next()
});
export default router;
