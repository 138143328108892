<template>
  <router-view></router-view>
  <section>
    <fintrivia-hero></fintrivia-hero>
    <!-- <fintrivia-judges></fintrivia-judges> -->
    <fintrivia-prizes></fintrivia-prizes>
    <fintrivia-steps></fintrivia-steps>
  </section>
</template>

<script>
import FintriviaHero from "../components/fintrivia/FintriviaHero.vue";
// import FintriviaJudges from "../components/fintrivia/FintriviaJudges.vue";
import FintriviaPrizes from "../components/fintrivia/FintriviaPrizes.vue";
import FintriviaSteps from "../components/fintrivia/FintriviaSteps.vue";

export default {
  components: {
    FintriviaHero,
    // FintriviaJudges,
    FintriviaPrizes,
    FintriviaSteps,
  },
  head() {
      return {
        title: "INVRS - Fintrivia Contest.",
        meta:[
          { 
            name: "description",
            content: 'Fintrivia is an ongoing financial trivia contest that tests your investment knowledge with monthly cash prizes. You can join at any time for free!'
          },
          { 
            name: "twitter:title",
            content: 'INVRS - Fintrivia Contest.'
          },
          { 
            name: "twitter:description",
            content: 'Fintrivia is an ongoing financial trivia contest that tests your investment knowledge with monthly cash prizes. You can join at any time for free!'
          },
          { 
            name: "og:title",
            content: 'INVRS - Fintrivia Contest.'
          },
          { 
            name: "og:description",
            content: 'Fintrivia is an ongoing financial trivia contest that tests your investment knowledge with monthly cash prizes. You can join at any time for free!'
          },
        ]
      }
    },
 
};
</script>
