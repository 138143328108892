<template>
  <router-view></router-view>
  <section >
    <contest-hero></contest-hero>
    <!-- <contest-judges></contest-judges> -->
    <contest-prizes></contest-prizes>
    <contest-steps></contest-steps>
  </section>
</template>

<script>
import ContestHero from "../components/contest/ContestHero.vue";
// import ContestJudges from "../components/contest/ContestJudges.vue";
import ContestPrizes from "../components/contest/ContestPrizes.vue";
import ContestSteps from "../components/contest/ContestSteps.vue";

export default {
  components: {
    ContestHero,
    // ContestJudges,
    ContestPrizes,
    ContestSteps,
  },
 
  head() {
      return {
        title: "INVRS - Learn2Earn Competitions.",
        meta:[
          { 
            name: "description",
            content: "Learn2Earn paper trading competitions emphasizing investing, day trading or penny stocks.  Monthly prizes for highest cumulative returns. Free to play!"
          },
          { 
            name: "twitter:title",
            content: 'INVRS - Learn2Earn Competitions.'
          },
          { 
            name: "twitter:description",
            content: "Learn2Earn paper trading competitions emphasizing investing, day trading or penny stocks.  Monthly prizes for highest cumulative returns. Free to play!"
          },
          { 
            name: "og:title",
            content: 'INVRS - Learn2Earn Competitions.'
          },
          { 
            name: "og:description",
            content: "Learn2Earn paper trading competitions emphasizing investing, day trading or penny stocks.  Monthly prizes for highest cumulative returns. Free to play!"
          },
        ]
      }
    },

};
</script>
