<template>
    <section class="section-hero courses-hero">
        <div class="hero-content">
            <h1 class="heading-primary">{{$t('Financial Education Courses')}}</h1>
            <div class="hero-founder main-section hidden-founder ceo-founder">
                <div class="founder-avatar">
                    <img src="/assets/now.png"  style="width:45rem;border-radius:16px;"/>
                    
                </div>

                <div class="main-side">
                   <p style="line-height:1.2;">{{$t('Courses for every stage of your investing journey.')}}
                   
                   </p>
                    
                    <button @click="goToSignup($event)" style="">
                      <span>{{$t('Click Here to Get Started')}}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 74 74"
                      >
                        <path
                          fill="white"
                          d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z"
                        ></path>
                      </svg>
                    </button>

                </div>
            </div>
            <div class="hidden-founder hero-founder cto-founder">
              <section class="home-education">
                <div class="container section-value-props section-education hidden">
                  <div class="section-header">
                    
                    <h2 class="heading-secondary">{{$t("Why Choose INVRS Financial Education?")}}</h2>
                  </div>
                  <div class="grid grid--2-cols">
                    <div class="value-box hidden">
                      
                      <h3 class="heading-tertiary">{{$t("Financial Education Designed for Your Busy Life")}}</h3>
                      <p class="value-description" v-html="$t('cour.eduDesignText')">
                      
                      </p>
                    </div>
                    <div class="value-box hidden">
                      
                      <h3 class="heading-tertiary" >{{$t("Investment Education Delivered How You Want it")}}</h3>
                      <p class="value-description" v-html="$t('cour.howYouWantText')">
                      </p>
                    </div>
                  </div>
                  <div class="grid grid--2-cols key-points" >
                    <div class="value-box hidden">
                      
                      <h3 class="heading-tertiary">{{$t("You Need Your Money for Other Things")}}</h3>
                      <p class="value-description" v-html="$t('cour.youNeedMoneyText')">
                      
                      </p>
                    </div>
                    <div class="value-box hidden">
                      
                      <h3 class="heading-tertiary" >{{$t("Have Fun Learning")}}</h3>
                      <p class="value-description" v-html="$t('cour.haveFunText')">
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class=" hero-founder cto-founder">
                <section class="home-education">
                  <div class="container section-value-props section-education hidden">
                    <div class="section-header">
                      
                      <h2 class="heading-secondary">{{$t("Customized Financial Education for Your Organization")}}</h2>
                    </div>
                    <div class="grid grid--2-cols custom-section">
                      <div class="value-box hidden custom-text" >
                        
                        
                        <p class="value-description" v-html="$t('cour.customText')">
                        
                        </p>
                      </div>
                      <div class="value-box hidden">
                        
                        <img src="/assets/Computer-screen-Elite-Athlete.png" class="custom-course-img"/>
                        
                      </div>
                    </div>
                    
                  </div>
                </section>
            </div>
            <div class=" hero-founder courses-header">
                <section class="home-education">
                  <div class="container section-value-props section-education hidden">
                    <div class="section-header">
                      
                      <h2 class="heading-secondary">{{$t("Explore Our Finance and Investment Courses")}}</h2>
                    </div>
                    <div class="">
                      <div class="value-box3 hidden">
                        
                        
                        <p class="value-description"> {{$t('previewPrompt')}}
                        
                        </p>
                      </div>
                      
                    </div>
                    
                  </div>
                </section>
            </div>
            
            <div class=" hero-founder cto-founder">
                <section class="home-education">
                  <div class="container section-value-props section-education hidden">
                    <div >
                      
                      <h2 class="heading-course-cat">{{$t("Beginner Level Courses")}}</h2>
                      
                    </div>
                    <div class="grid">
                      <div class="value-box hidden">
                        
                        
                          <h3 class="heading-tertiary">{{$t("Foundation")}}</h3>
                         
                        
                            <p class="value-description" v-html="$t('cour.foundation')">
                            
                            </p>
                          
                          <a
                            class="cta btn" 
                            href="#"
                            @mouseenter="closeDropdown"
                            @click="goToSignup($event,'found')" style="margin-bottom:5px;padding:1rem 2rem;float:right;">{{$t('More Info')}}&rarr;</a>
                          <div class="pricing-header">
                            <span>Price:</span>
                            <div class="course-pricing">
                              <span class="course-price"><span style="text-decoration:line-through;font-size:1.5rem;">$69.95</span>&nbsp;$39.95 USD</span>
                              <span class="course-price"><span style="text-decoration:line-through;font-size:1.5rem;">$99.95</span>&nbsp;$56.95 CAD</span>
                            </div>
                          </div>
                      </div>

                    
                      <div class="value-box hidden">
                        
                        <h3 class="heading-tertiary">{{$t("Financial Literacy for Everyone")}}</h3>
                        <p class="value-description" v-html="$t('cour.literacy')">
                        
                        </p>
                        <a
                          class="cta btn"
                          href="#"
                          @mouseenter="closeDropdown"
                          @click="goToSignup($event,'lit')" style="margin-bottom:5px;padding:1rem 2rem;float:right;">{{$t('More Info')}}&rarr;</a>

                        <div class="pricing-header">
                            <span>Price:</span>
                            <div class="course-pricing">
                              <span class="course-price"><span style="text-decoration:line-through;font-size:1.5rem;">$69.95</span>&nbsp;$39.95 USD</span>
                              <span class="course-price"><span style="text-decoration:line-through;font-size:1.5rem;">$99.95</span>&nbsp;$56.95 CAD</span>
                            </div>
                          </div>
                      </div>
                      <div class="value-box hidden">
                        
                        <h3 class="heading-tertiary">{{$t("Fondations d'Investissement")}}</h3>
                        <p class="value-description" >
                        Acquérir les bases de l'investissement, y compris l'histoire, la terminologie et les concepts. Dans ce cours, vous Préparerez le Terrain en apprenant ce qu'est l'investissement, un peu de son histoire et une partie de la terminologie. Ensuite, vous Construirez les Fondations en assimilant des concepts fondamentaux, ainsi que des compétences pratiques, comme la façon de passer des ordres. L'investissement est un sujet vaste et multidisciplinaire. Il s'appuie sur les théories économiques, la pratique comptable et la science des statistiques. Nous aimons le voir comme un voyage, oùvous décidez de la destination et des outils que vous souhaitez utiliser pour y arriver. Notre rôle est de vous aider à prendre des décisions éclairées.
                        </p>
                        <a
                          class="cta btn"
                          href="#"
                          @mouseenter="closeDropdown"
                          @click="goToSignup($event,'fond')" style="margin-bottom:5px;padding:1rem 2rem;float:right;">{{$t('More Info')}}&rarr;</a>

                        <div class="pricing-header">
                          <span>Price:</span>
                          <div class="course-pricing">
                            <!-- <span class="course-price"><font style="text-decoration:line-through;font-size:1.5rem;">$69.95</font>&nbsp;$39.95 USD</span> -->
                            <span class="course-price"><span style="text-decoration:line-through;font-size:1.5rem;">$99.95</span>&nbsp;$56.95 CAD</span>
                          </div>
                        </div>

                      </div>
                       <div class="value-box hidden">
                        
                        <h3 class="heading-tertiary">{{$t("Littératie Financière Pour Tous")}}</h3>
                        <p class="value-description">Ce cours complet est conçu pour doter les apprenants des connaissances et des compétences financières essentielles, cruciales pour gérer efficacement leur argent et prendre des décisions financières éclairées. À travers des leçons captivantes, des exemples concrets et des activités pratiques, les étudiants apprendront à naviguer dans le monde financier en toute confiance et à bâtir une base solide pour leur avenir financier.
                        
                        </p>
                        <a
                          class="cta btn"
                          href="#"
                          @mouseenter="closeDropdown"
                          @click="goToSignup($event,'litf')" style="margin-bottom:5px;padding:1rem 2rem;float:right;">{{$t('More Info')}}&rarr;</a>
                        
                        <div class="pricing-header">
                            <span>Price:</span>
                            <div class="course-pricing">
                              <!-- <span class="course-price"><font style="text-decoration:line-through;font-size:1.5rem;">$69.95</font>&nbsp;$39.95 USD</span> -->
                              <span class="course-price"><span style="text-decoration:line-through;font-size:1.5rem;">$99.95</span>&nbsp;$56.95 CAD</span>
                            </div>
                          </div>
                      </div>
                    </div>
                    
                  </div>
                </section>
            </div>
            <div class=" hero-founder cto-founder">
                <section class="home-education">
                  <div class="container section-value-props section-education hidden">
                    <div >
                      
                      <h2 class="heading-course-cat">{{$t("Intermediate Level Courses")}}</h2>
                      
                    </div>
                    <div class="grid">
                      <div class="value-box hidden">
                        
                        <h3 class="heading-tertiary">{{$t("Build and Manage a Goal Achieving Portfolio")}}</h3>
                        <p class="value-description" >
                        This course is for Do-It-Yourself investors or those considering it.  Why be a DIY investor?  First, it can be very rewarding to see your portfolio grow through your own efforts.  Second, you will save on the management fees and finally, you may feel that you can do better.  The focus of this course is about building and managing a goal-achieving portfolio that transitions over time.  You learn by doing in this course, as you buy and sell ETFs to create a diversified portfolio.  The course provides new and insightful information about determining your risk profile, diversification, portfolio phases, planning your portfolio structure, and a cadence for buying securities.  Note, this is not a stock picking course. 
                        </p>
                        <a
                          class="cta btn"
                          href="#"
                          @mouseenter="closeDropdown"
                          @click="goToSignup($event,'build')" style="margin-bottom:5px;padding:1rem 2rem;float:right;">{{$t('More Info')}}&rarr;</a>
                        
                          <div class="pricing-header">
                            <span>Price:</span>
                            <div class="course-pricing">
                              <span class="course-price"><span style="text-decoration:line-through;font-size:1.5rem;">$104.95</span>&nbsp;$69.95 USD</span>
                              <span class="course-price"><span style="text-decoration:line-through;font-size:1.5rem;">$149.95</span>&nbsp;$99.95 CAD</span>
                            </div>
                          </div>
                      </div>
                      <div class="value-box hidden">
                        
                        <h3 class="heading-tertiary">{{$t("More Intermediate Level Courses Coming Soon")}}</h3>
                        <p class="value-description">
                          {{$t('New Courses coming soon in the following topics')}}:
                          <ul style="margin-left: 3rem;">
                            <li>{{$t('Income Portfolios')}}</li>
                            <li>{{$t('Stock Picking for Growth')}}</li>
                            <li>{{$t('Bond Strategies')}}</li>
                            <li>{{$t('Understanding Options')}}</li>
                          </ul>
                        </p>
                      </div>
                      
                       
                    </div>
                    
                  </div>
                </section>
            </div>
            <div class=" hero-founder cto-founder">
                <section class="home-education">
                  <div class="container section-value-props section-education hidden">
                    <div >
                      
                      <h2 class="heading-course-cat">{{$t("Specialty Courses")}}</h2>
                      
                    </div>
                    <div class="grid">
                      <div class="value-box hidden">
                        
                        <h3 class="heading-tertiary">{{$t("Introduction to Financial Careers")}}</h3>
                        <p class="value-description" >
                        This comprehensive course is designed to provide learners with a thorough understanding of various careers within the financial industry. Through detailed lessons, real-world insights, and practical knowledge, students will explore roles in banking, financial planning, corporate finance, and more, equipping them with the tools and information needed to pursue a successful career in finance.
                        </p>
                        <a
                          class="cta btn"
                          href="#"
                          @mouseenter="closeDropdown"
                          @click="goToSignup($event,'career')" style="margin-bottom:5px;padding:1rem 2rem;float:right;">{{$t('More Info')}}&rarr;</a>

                        <div class="pricing-header">
                          <span>Price:</span>
                          <div class="course-pricing">
                            <span class="course-price"><span style="text-decoration:line-through;font-size:1.5rem;">$69.95</span>&nbsp;$39.95 USD</span>
                            <span class="course-price"><span style="text-decoration:line-through;font-size:1.5rem;">$99.95</span>&nbsp;$56.95 CAD</span>
                          </div>
                        </div>
                      </div>
                      
                      <div class="value-box hidden">
                        
                        <h3 class="heading-tertiary">{{$t("Recruitment Prep")}}</h3>
                        <p class="value-description" >
                        The competition for internships at top firms is fierce. Give yourself an edge with Recruitment Prep, the course to train finance students in landing their dream internship.
                        </p>
                        <a
                          class="cta btn"
                          href="#"
                          @mouseenter="closeDropdown"
                          @click="goToSignup($event,'prep')" style="margin-bottom:5px;padding:1rem 2rem;float:right;">{{$t('More Info')}}&rarr;</a>

                        <div class="pricing-header">
                          <span>Price:</span>
                          <div class="course-pricing">
                            <!-- <span class="course-price"><font style="text-decoration:line-through;font-size:1.5rem;">$86.95</font>&nbsp;$52.95 USD</span> -->
                            <span class="course-price"><span style="text-decoration:line-through;font-size:1.5rem;">$124.95</span>&nbsp;$74.95 CAD</span>
                          </div>
                        </div>
                      </div>
                      
                      <div class="value-box hidden">
                        
                        <h3 class="heading-tertiary">{{$t("Game Plan: Financial Literacy for Elite Athletes")}}</h3>
                        <p class="value-description" >
                        This course equips CHL and NHL players with essential financial literacy skills to navigate sudden wealth. Covering topics like contract breakdowns, agent fees, and investment strategies, it emphasizes the importance of budgeting, long-term planning, and the value of higher education. Players will learn to establish a division of duties among financial advisors to protect their interests and adapt their mindset from immediate gratification to sustainable wealth growth. This practical guide aims to empower athletes to make informed financial decisions that ensure lasting financial success both on and off the ice.
                        </p>
                        <a
                          class="cta btn"
                          href="#"
                          @mouseenter="closeDropdown"
                          @click="goToSignup($event,'game')" style="margin-bottom:5px;padding:1rem 2rem;float:right;">{{$t('More Info')}}&rarr;</a>
                        <div class="pricing-header">
                          <span>Price:</span>
                          <div class="course-pricing">
                            
                            <span class="course-price"><span style="text-decoration:line-through;font-size:1.5rem;">$149.95</span>&nbsp;$99.00 CAD</span>
                          </div>
                        </div>
                      </div>
                     
                      
                       
                    </div>
                    
                  </div>
                </section>
            </div>
        </div>
    </section>
  </template>
  
  <script>
import { SIGNUP_URL } from "../config.js";


  export default {
    name: "CoursesPage",

    components: {
       // Avatar
    },
    head() {
      return {
        title: "INVRS - Courses",
        meta:[
          { 
            name: "description",
            content: 'We offer beginner courses like Financial Literacy and Investment Foundations, Intermediate courses like Build and Manage an Investment Portfolio, career courses like Recruitment Prep, and specialty courses like Game Plan: Financial Literacy for Elite Athletes.'
          },
          { 
            name: "twitter:title",
            content: 'INVRS - Courses.'
          },
          { 
            name: "twitter:description",
            content: 'We offer beginner courses like Financial Literacy and Investment Foundations, Intermediate courses like Build and Manage an Investment Portfolio, career courses like Recruitment Prep, and specialty courses like Game Plan: Financial Literacy for Elite Athletes.'
          },
          { 
            name: "og:title",
            content: 'INVRS - Courses.'
          },
          { 
            name: "og:description",
            content: 'We offer beginner courses like Financial Literacy and Investment Foundations, Intermediate courses like Build and Manage an Investment Portfolio, career courses like Recruitment Prep, and specialty courses like Game Plan: Financial Literacy for Elite Athletes.'
          },
        ]
      }
    },

    data() {
      return {
        heroSection: "",
        heroContent: "",
        ceoFounder: "",
        ctoFounder: "",
        ceoFounderImagePath: "../../assets/ceo-founder.jpg",
        obsOptionsHero: {
          root: null,
          threshold: 0.1,
        },
      };
    },
    mounted() {
      this.heroSection = document.querySelector(".courses-hero");
      this.ceoFounder = document.querySelector(".ceo-founder");
      this.ctoFounder = document.querySelector(".cto-founder");
      const heroObserver = new IntersectionObserver(
        this.revealHero,
        this.obsOptionsHero
      );
      try {
        heroObserver.observe(this.heroSection);
      }catch( err ){
        console.log("error doing heroObserver.observe with ", this.heroSection, err);
      }
    },
    methods: {
      revealFounders() {
        this.ceoFounder.classList.remove("hidden-founder");
        this.ctoFounder.classList.remove("hidden-founder");
      },
      revealHero() {
        this.revealFounders();
      },
      goToSignup(evt, courseCode) {
        evt.preventDefault();
        if( courseCode ){
          window.location.href = SIGNUP_URL+"?courseCode="+courseCode;
        }
        else {
          window.location.href = SIGNUP_URL
        }
      },
    },
  };
  </script>
  
  <style scoped>

    .course-price{
      font-size: 1.8rem;
    }
    .course-pricing{
      display:flex;
      flex-direction: column;
      
      margin-left:5px;
    }

    .pricing-header{
      display:flex;
      flex-direction: row;
      margin-top:5px;
      /*max-width: 30rem;*/
      align-items: center;
    }


    .custom-text {
      padding-bottom:5rem;
    }
    .custom-section{
      align-items: center;
    }
    .custom-course-img{
      max-width:45rem;
    }
    .value-description{
      margin-bottom: 0;
    }

    .value-description {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }

    .heading-course-cat {
      font-size: 3.2rem;
      color: #333;
      margin-bottom: 4.8rem;
      line-height: 1.1;
    }

   .courses-header{
      flex-direction: column;
   }

  .grid--2-cols {
      grid-template-columns: repeat(2, 1fr);
  }

  .key-points{
    margin-top:2rem;
  }

  .section-hero {
    position: relative;
    padding: 12.8rem 0 9.6rem 0;
    
  }
  
  .hero-content {
    max-width: 130rem;
    margin: 0 auto;
    padding: 0 3.6rem;
  }

  .heading-primary {
    margin-bottom: 3.8rem;
  }

  .hidden-founder {
    opacity: 0;
  }
  .hidden-content {
    transform: translateX(-9.6rem);
    opacity: 0;
  }
  .hidden-founder.ceo-founder {
    transform: translateX(-9.6rem);
  }

  .main-section {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5rem;
    transition: all 0.6s ease-in;
    padding-left: 11rem;
  }

  .hero-founder {
    display: flex;
    align-items: center;
    margin-bottom: 5rem;
    transition: all 0.6s ease-in;
  }
  .hero-founder:last-of-type() {
    margin-bottom: 0;
  }

  .founder-heading {
    color: #32364e;
    font-size: 2.5rem;
  }

  .founder-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .founder-avatar .p-avatar {
    width: 18rem;
    height: 18rem;
    border: 3px solid #32364e;
  }

  .main-side {
    padding: 2rem;
    
    line-height: 1.6;
    
    max-width: 100rem;
    font-size:6rem;
    margin-left:3rem;
   
  }

  .section-header {
    text-align: center;
  }

  .founder-description {
    padding: 2rem;
    border-radius: 25px;
    line-height: 1.6;
    color: #333;
    max-width: 100rem;
    background: #fff;
    box-shadow: 0 0 2.4rem hsl(231deg 22% 25% / 20%);
  }

  .ceo-founder .founder-avatar {
    /*margin-right: 6rem;*/
  }
  
  .hidden-founder.cto-founder {
    transform: translateX(9.6rem);
  }

  .cto-founder {
    flex-direction: row-reverse;
  }
  
  .cto-founder .founder-avatar {
    margin-left: 6rem;
  }

   .heading-primary {
      font-size: 4rem;
      text-align: center;
    }

  .heading-secondary {
    font-size: 3.8rem;
  }

button {
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  color: #fff;
  transition: all 0.2s;
  padding: 10px 20px;
  border-radius: 0.8rem;
  background-image: linear-gradient(
    to right,
    rgb(105, 59, 245, 1),
    rgb(105, 59, 245, 0.85),
    rgb(105, 59, 245, 0.7)
  );
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 2rem;
  max-width: fit-content;
}

button:hover {
  box-shadow: 0 0.4rem 3.2rem rgb(105, 59, 245, 0.6);
  transform: scale(1.05);
}

button > svg {
  width: 3.6rem;
  margin-left: 1rem;
  transition: transform 0.3s ease-in-out;
  font-weight: 700;
}

button:hover svg {
  transform: translateX(5px);
}

button:active {
  transform: scale(0.95);
}



  /* **************************** */
  /* BELOW 1360px (Small desktop/laptop) */
  /* **************************** */
  @media (max-width: 85em) {
    .hero-content {
        max-width: 150rem;
    }
  
    .heading-primary {
      font-size: 4rem;
    }

    .founder-description {
        max-width: 75rem;
    }

    .main-side {
      max-width: 75rem;
    }
  }
  
  /* **************************** */
  /* BELOW 976px (landscape tablets) */
  /* **************************** */
  @media (max-width: 61em) {
    .hero-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .hero-founder {
        flex-direction: column;
        justify-content: center;
    }

    .founder-avatar {
        margin: 0 6rem 0;
    }

    .founder-description,
    .cto-founder .founder-description  {
        text-align: center;
        max-width: 90rem;
    }

    .main-side {
      text-align: center;
      max-width: 50rem;
      align-items: center;
      justify-content: center;
      display:flex;
      flex-direction: column;

    }
    .grid--2-cols {
         grid-template-columns: 1fr;
    }

    .custom-text {
      padding-bottom:0;
    }

    .custom-course-img{
      max-width:35rem;
    }
           
    .heading-primary {
      font-size: 3.6rem;
    }

    .heading-secondary {
      margin-bottom: 2.8rem;
    }
    .heading-course-cat {
      margin-bottom: 2.8rem;
    }

    .container{
      max-width: 100%;
      padding: 0;
    }

    .main-section{
      padding-left: 0;
    }
  }
  
  /* **************************** */
  /* BELOW 800px (tablet) */
  /* **************************** */
  @media (max-width: 50em) {
  }
  
  /* **************************** */
  /* BELOW 592px (mobile) */
  /* **************************** */
  @media (max-width: 37em) {
  
    .heading-primary {
      font-size: 3rem;
    }

    .founder-heading {
        font-size: 2rem;
    }

    .founder-avatar .p-avatar {
        width: 15rem;
        height: 15rem;
    }

    .founder-description {
        padding: 1.2rem;
        font-size: 1.8rem;
        border-radius: 10px;
    }

    .main-side {
      font-size: 3rem;
      margin-left: 0;
    }
  }
  
  /* **************************** */
  /* BELOW 496px (mobile) */
  /* **************************** */
  @media (max-width: 31em) {
    .heading-primary {
      font-size: 2.4rem;
    }

     button {
      padding: 8px 16px;
    }
  }
  </style>
  